const messages = {
  copy: {
    first: 'HP blok',
    last: 'byl zkopírován'
  },
  list: {
    id: 'Id',
    type: 'Typ',
    title: 'Název',
    identifier: 'Identifikátor',
    number_of_items: 'Počet položek',
    external_fallback: 'Externí záloha',
    internal_fallback: 'Interní záloha',
    fallback_article_types: 'Záložní typy článků',
    fallback_article_flags: 'Záložní příznaky článků',
    hidden: 'Skryto',
    position: 'Pozice',
    created_at: 'Vytvořeno',
    modified_at: 'Upraveno',
    created_by: 'Vytvořil',
    fallback_settings: 'Záložní nastavení'
  },
  filter: {
    site: 'Web',
    type: 'Typ',
    title: 'Název',
    identifier: 'Identifikátor',
    id: 'Id',
    search: 'Hledat'
  },
  id: 'Id',
  hidden: 'Skryto',
  type: 'Typ',
  title: 'Název',
  identifier: 'Identifikátor',
  site: 'Web',
  category: 'Kategorie',
  number_of_items: 'Počet položek',
  position: 'Pozice',
  daysLimit: 'Omezení dnů dotazu',
  display_position: 'Pozice zobrazení',
  disabled_positions: 'Zakázané pozice',
  fallback_positions: 'Záložní pozice',
  external_fallback: 'Povolit externí zálohu',
  internal_fallback: 'Povolit interní zálohu',
  fallback_site: 'Záložní web',
  fallback_rubrics: 'Záložní rubriky',
  fallback_tags: 'Záložní štítky',
  fallback_article_types: 'Typy článků (použije se logické NEBO)',
  fallback_article_flags: 'Příznaky článků (použije se logické NEBO)',
  fallback_article_settings: 'Nastavení článků',
  fallback_categories: 'Záložní kategorie',
  content_block_type_value: {
    article: 'Článek',
    video_article: 'Video článek',
    video: 'Video'
  },
  main_settings: 'Hlavní nastavení',
  fallback_settings: 'Záložní nastavení',
  gallery: 'Galerie',
  pr: 'Jen PR články'
}

export default messages
