export default {
  header_1: 'NAVIGACE',
  system: 'Systém',
  dashboard: 'Realtime statistiky',
  dashboard_old: 'Realtime statistiky (old)',
  articles: 'Seznam článků',
  dam: 'DAM',
  dam_images: 'Obrázky',
  dam_pdf: 'Dokumenty',
  video: 'Video',
  video_list: 'Videa',
  video_list_old: 'Videa(old)',
  video_management: 'Video management',
  video_show_list: 'PLUS TV pořady',
  video_category_list: 'PLUS TV kategorie',
  video_stats: 'Statistiky videí',
  video_kpi_reports: 'Report uploadovaných videí',
  don: 'Sponzorované články',
  don_campaign_management: 'Management kampaní',
  don_campaign_management_old: 'Management kampaní (old)',
  don_campaign_predictions: 'Vývoj kampaní',
  infoboxes: 'Infoboxy',
  serials: 'Seriály',
  themeSpecialAndSeries: 'Témata, speciály a seriály',
  tags: 'Tagy',
  commonTags: 'Tagy',
  editorialTags: 'Redakční tagy',
  geneeaTags: 'Geneea tagy',
  jokes: 'Vtipy',
  quotes: 'Myšlenka týdne',
  categories: 'Kategorie',
  safetyTopics: 'Safety topics',
  polls: 'Ankety',
  polls_on_homepage: 'Ankety na Homepage',
  like_dislike: 'Líbí nelíbí',
  quizzes: 'Kvízy',
  oneQuestion: 'Jedna otázka',
  content_blocks: 'Content Blocks',
  poll_blocks: 'Poll Blocks',
  content_block_items: 'HP management',
  content_block_items_old: 'HP management(old)',
  rubrics: 'Rubriky',
  authors: 'Autoři',
  sources: 'Zdroje',
  otherFunctions: 'Další funkce',
  redirects: 'Přesměrování',
  modules: 'Moduly',
  statistics: 'Statistiky',
  historical_dashboard: 'Statistiky produktu',
  beUsers: 'Backend Uživatelé',
  performance: 'Editor výkonnost',
  beUserKpi_list: 'Editor KPI',
  kpi_comparison: 'Porovnání KPI',
  performance_product: 'Výkonnost produktu',
  performance_department: 'Výkonnost oddělení',
  historical_performance: 'Historická výkonnost',
  edonProduct_list: 'Produkty',
  scale_list: 'Scale Editor',
  feUsers: 'Uživatelé',
  feUsersDeliveryAddresses: 'Doručovací adresy',
  feUsersBillingAddresses: 'Fakturační adresy',
  feUsersArticles: 'Články',
  feUsersPollVotes: 'Ankety',
  all_articles: 'Všechny články',
  sites: 'Sites',
  features: 'Features',
  articleHistory: 'Article history',
  sport24: 'Sportovní tabulky',
  userLoginAttempts: 'User Login Attempts',
  notificationServiceNotifications: 'Notifikace',
  tasrNews: 'TASR',
  tasrNewsCategory: 'TASR news category',
  topicOffer: 'Nabídka obsahu',
  article_offer: 'Nabídky článků',
  print: 'Print',
  printPublications: 'Printové publikace',
  printTitles: 'Printové tituly',
  printArticles: 'Printové články',
  topic: 'Témata',
  eshop: 'Eshop',
  eshopSubscriptionProducts: 'Produkty',
  eshopTopProductSetting: 'TOP Produkty',
  eshopSubscriptionOrders: 'Objednávky',
  eshopHistory: 'Historie',
  eshopSubscriptionPayments: 'Platby',
  eshopSubscriptions: 'Předplatné',
  eshopSubscribers: 'Předplatitelé',
  eshopFeatures: 'Eshop features',
  eshopDashboard: 'Dashboard',
  tests: 'Testy',
  cars: 'Vozidla',
  restaurants: 'Restaurace',
  hotels: 'Hotely',
  departments: 'Oddělení',
  app_performance: 'App Performance',
  video_daily_report: 'Report redakčních videí',
  video_published_report: 'Report publikovaných videí',
  performance_weights: 'Nastavení vah',
  permission_groups: 'Skupiny oprávnění',
  kpi_settings: 'Nastavení KPI',
  kpi_nmh: 'NMH KPI',
  kpi_nmh_old: 'Historické NMH KPI',
  kpi_nmh_settings: 'NMH KPI nastavení',
  recipes: 'Recepty',
  recipe_categories: 'Kategorie receptu',
  hp_topic_offer: 'HP nabídka článků',
  hp_topic_offer_old: 'HP nabídka článků(old)',
  hp: 'Homepage',
  wood_wing: 'WoodWing',
  videoTutorial: 'Video návod',
  language: 'Nastavení jazyka',
  galleries: 'Galerie',
  hottopics: 'Hot Topics',
  breakingnews: 'Breaking News',
  right_now: 'Právě teď',
  embedded_application: 'VLM aplikace',
  menu_editor: 'Menu editor'
}
