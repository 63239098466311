const messages = {
  all_slots: 'Vše',
  approved: 'schváleno',
  articles_missing: '2 články nebyly doporučeny.',
  department_articles_missing: 'Články ze střediska {department} nebyly dosud doručeny.',
  base: 'Středisko',
  date: 'Datum publikování na HP',
  disapprove: 'Odebrat',
  fb_shared: 'Přidáno na FB',
  pageViews: 'PVs',
  slot_base: 'Slot - středisko',
  site: 'Stránka',
  dateSince: 'Od kdy má být článek na HP',
  dateUntil: 'Do kdy má být článek na HP',
  buttons: {
    select_article: 'Vyber z HP nabídky'
  },
  list: {
    headers: {
      title: 'Titulek',
      subTitle: 'Slot - Stredisko - Stránka - Rubrika',
      pv: 'PV`s',
      publishWeb: 'Datum publikování na webe',
      publishHp: 'Datum publikování na HP',
      fb: 'Přiřazeno na FB',
      actions: 'Akce'
    }
  }
}

export default messages
