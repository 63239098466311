const messages = {
  home: 'Domů',
  article_list: 'Seznam článků',
  article_detail: 'Detail článku',
  article_edit: 'Editace článku',
  articleByDocumentId: 'Detail článku',
  author_list: 'Seznam autorů',
  author_detail: 'Detail autora',
  author_edit: 'Editace autora',
  author_new: 'Nový autor',
  breakingnews_edit: 'Editovat zprávu',
  breakingnews_list: 'Breaking news',
  breakingnews_new: 'Vytvořit novou zprávu',
  source_list: 'Seznam zdrojů',
  source_detail: 'Detail zdroje',
  source_edit: 'Editace zdroje',
  source_new: 'Nový zdroj',
  otherFunction_list: 'Seznam dalších funkcí',
  otherFunction_detail: 'Detail další funkce',
  otherFunction_edit: 'Editace další funkce',
  otherFunction_new: 'Nová další funkce',
  contentBlock_list: 'Seznam content bloků',
  contentBlock_detail: 'Detail content bloku',
  contentBlock_edit: 'Editace content bloku',
  contentBlock_new: 'Nový content blok',
  pollBlock_list: 'Seznam poll bloků',
  pollBlock_detail: 'Detail poll bloku',
  pollBlock_edit: 'Editace poll bloku',
  pollBlock_new: 'Nový poll blok',
  topic_list: 'Seznam témat',
  topic_detail: 'Detail tématu',
  topic_edit: 'Editace tématu',
  topic_new: 'Nové téma',
  car_list: 'Seznam vozidel',
  car_detail: 'Detail vozidla',
  car_edit: 'Editace vozidla',
  car_new: 'Nové vozidlo',
  restaurant_list: 'Seznam restaurací',
  restaurant_detail: 'Detail restaurace',
  restaurant_edit: 'Editace restaurace',
  restaurant_new: 'Nová restaurace',
  hotel_list: 'Seznam hotelů',
  hotel_detail: 'Detail hotelu',
  hotel_edit: 'Editace hotelu',
  hotel_new: 'Nový hotel',
  contentBlockItem_list: 'Správa homepage',
  contentBlockItem_list_old: 'Správa homepage (staré)',
  dam_list: 'Seznam fotek DAM',
  dam_pdf_list: 'Seznam dokumentů DAM',
  native_campaign_list: 'Přehled spuštěných kampaní',
  native_campaign_detail: 'Náhled kampaně',
  native_campaign_edit: 'Upravit kampaň',
  native_campaign_duplicate: 'Zduplikovat kampaň',
  native_campaign_from_article: 'Vytvořit kampaň z článku',
  native_campaign_new: 'Vytvořit kampaň',
  don_npa_list: 'Správa kampaní',
  don_npa_predictions: 'Vývoj kampaní',
  don_npa_detail: 'Náhled kampaně',
  don_npa_edit: 'Upravit kampaň',
  don_npa_duplicate: 'Zduplikovat kampaň',
  don_npa_new: 'Vytvořit kampaň',
  don_npa_config: 'Konfigurace DON boxu',
  dashboard: 'Realtime statistiky',
  dashboard_old: 'Realtime statistiky (staré)',
  welcome: 'Vítejte',
  infobox_list: 'Seznam infoboxů',
  infobox_detail: 'Detail infoboxu',
  infobox_edit: 'Editace infoboxu',
  infobox_new: 'Nový infobox',
  joke_list: 'Seznam vtipů',
  joke_detail: 'Detail vtipu',
  joke_edit: 'Editace vtipu',
  joke_new: 'Nový vtip',
  quote_list: 'Seznam myšlenek týdne',
  quote_detail: 'Detail myšlenky týdne',
  quote_edit: 'Editace myšlenky týdne',
  quote_new: 'Nová myšlenka týdne',
  poll_list: 'Seznam anket',
  poll_detail: 'Detail ankety',
  poll_edit: 'Editace ankety',
  poll_new: 'Nová anketa',
  poll_management: 'Ankety na homepage',
  likeDislike_list: 'Seznam líbí/nelíbí',
  likeDislike_detail: 'Detail líbí/nelíbí',
  likeDislike_edit: 'Editace líbí/nelíbí',
  likeDislike_new: 'Nové líbí/nelíbí',
  quiz_list: 'Seznam kvízů',
  quiz_detail: 'Detail kvízu',
  quiz_edit: 'Editace kvízu',
  quiz_new: 'Nový kvíz',
  quizQuestion_new: 'Nová otázka kvízu',
  quizQuestion_edit: 'Editace otázky kvízu',
  oneQuestion_list: 'Seznam - jedna otázka',
  oneQuestion_detail: 'Detail - jedna otázka',
  oneQuestion_edit: 'Editace - jedna otázka',
  oneQuestion_new: 'Nová - jedna otázka',
  oneQuestionQuestion_new: 'Nová otázka jedné otázky',
  oneQuestionQuestion_edit: 'Editace otázky jedné otázky',
  historical_dashboard: 'Statistiky produktu',
  beUserKpi_list: 'Správa KPI',
  beUserKpi_edit: 'Upravit KPI',
  kpi_comparison: 'Srovnání KPI',
  performance_kpi_settings: 'Nastavení KPI',
  performance_product: 'Výkonnost produktu',
  performance_department: 'Výkonnost oddělení',
  historical_performance: 'Historická výkonnost',
  performance_coefficient: 'Nastavení koeficientů',
  performance_weights: 'Nastavení vah',
  edonProduct_list: 'Produkty',
  edonProduct_new: 'Nový produkt',
  edonProduct_edit: 'Upravit produkt',
  beUser_list: 'Seznam BE uživatelů',
  beUser_edit: 'Editace BE uživatele',
  beUser_new: 'Nový BE uživatel',
  feUser_list: 'Seznam FE uživatelů',
  feUser_edit: 'Editace FE uživatele',
  feUser_new: 'Nový FE uživatel',
  feUserArticle_list: 'Seznam článků FE uživatele',
  feUserArticle_edit: 'Editace článku FE uživatele',
  feUserArticle_new: 'Nový článek FE uživatele',
  feUserBillingAddress_list: 'Seznam fakturačních adres FE uživatele',
  feUserBillingAddress_edit: 'Editace fakturační adresy FE uživatele',
  feUserBillingAddress_new: 'Nová fakturační adresa FE uživatele',
  feUserDeliveryAddress_list: 'Seznam dodacích adres FE uživatele',
  feUserDeliveryAddress_edit: 'Editace dodací adresy FE uživatele',
  feUserDeliveryAddress_new: 'Nová dodací adresa FE uživatele',
  feUserPollVote_list: 'Ankety',
  hottopics_edit: 'Hot Topics - editace panelu',
  hottopics_list: 'Hot Topics - seznam panelů',
  hottopics_new: 'Hot Topics - nový panel',
  site_list: 'Seznam webů',
  site_edit: 'Editace webu',
  rubric_list: 'Seznam rubrik',
  rubric_edit: 'Editace rubriky',
  rubric_new: 'Nová rubrika',
  category_list: 'Seznam kategorií',
  category_edit: 'Editace kategorie',
  category_new: 'Nová kategorie',
  tag_list: 'Seznam tagů',
  tag_edit: 'Editace tagu',
  tag_new: 'Nový tag',
  commonTags_list: 'Seznam běžných tagů',
  geneeaTags_list: 'Seznam tagů Geneea',
  geneeaTag_edit: 'Editace tagu Geneea',
  geneeaTag_detail: 'Detail tagu Geneea',
  editorialTags_list: 'Seznam redakčních tagů',
  editorialTag_edit: 'Editace redakčního tagu',
  editorialTag_detail: 'Detail redakčního tagu',
  editorialTag_new: 'Nový redakční tag',
  themeSpecialAndSeries_list: 'Seznam témat, speciálů a seriálů',
  themeSpecialAndSeries_edit: 'Editace tématu, speciálu nebo seriálu',
  special_detail: 'Detail speciálu',
  theme_detail: 'Detail tématu',
  series_detail: 'Detail seriálu',
  themeSpecialAndSeries_detail: 'Detail tématu, speciálu nebo seriálu',
  themeSpecialAndSeries_new: 'Nové téma, speciál nebo seriál',
  department_list: 'Seznam oddělení',
  department_edit: 'Editace oddělení',
  department_new: 'Nové oddělení',
  app_performance: 'Výkon aplikace',
  scale_list: 'Seznam vah',
  scale_edit: 'Editace váhy',
  safetyTopic_list: 'Seznam bezpečnostních témat',
  safetyTopic_edit: 'Editace bezpečnostního tématu',
  safetyTopic_new: 'Nové bezpečnostní téma',
  redirect_list: 'Seznam přesměrování',
  redirect_edit: 'Editace přesměrování',
  redirect_new: 'Nové přesměrování',
  redirect_detail: 'Detail přesměrování',
  feature_list: 'Seznam funkcí',
  feature_edit: 'Editace funkce',
  articleHistory_list: 'Seznam historie článků',
  sport24_list: 'Sportovní tabulky',
  sport24_detail: 'Detail sportovní tabulky',
  help_video_list: 'Seznam videí nápovědy',
  userLoginAttempt_list: 'Seznam pokusů o přihlášení',
  tasrNews_list: 'Seznam TASR zpráv',
  tasrNews_detail: 'Detail TASR zprávy',
  tasrNewsCategory_list: 'Seznam kategorií TASR zpráv',
  tasrNewsCategory_edit: 'Editace kategorie TASR zpráv',
  tasrNewsCategory_new: 'Nová kategorie TASR zpráv',
  topicOffer_list: 'Seznam nabídek témat',
  topicOffer_detail: 'Detail nabídky tématu',
  printTitle_list: 'Seznam tiskových titulů',
  printTitle_detail: 'Detail tiskového titulu',
  printTitle_edit: 'Editace tiskového titulu',
  printTitle_new: 'Nový tiskový titul',
  printPublication_preview: 'Náhled aktuálních tiskových vydání na webu',
  printPublication_list: 'Seznam tiskových vydání',
  printPublication_detail: 'Detail tiskového vydání',
  printPublication_edit: 'Editace tiskového vydání',
  printPublication_new: 'Nové tiskové vydání',
  video_list: 'Seznam videí',
  video_list_old: 'Seznam videí (staré)',
  video_detail: 'Detail videa',
  video_detail_old: 'Detail videa (staré)',
  video_edit: 'Editace videa',
  video_edit_old: 'Editace videa (staré)',
  video_new: 'Nové video',
  video_new_old: 'Nové video (staré)',
  videoAnnotation_list: 'Seznam anotací',
  videoAnnotation_list_old: 'Seznam anotací (staré)',
  videoAnnotation_detail: 'Detail anotace',
  videoAnnotation_detail_old: 'Detail anotace (staré)',
  videoAnnotation_edit: 'Editace anotace',
  videoAnnotation_edit_old: 'Editace anotace (staré)',
  videoAnnotation_new: 'Nová anotace',
  videoAnnotation_new_old: 'Nová anotace (staré)',
  videoShow_list: 'Seznam video show',
  videoShow_detail: 'Detail video show',
  videoShow_edit: 'Editace video show',
  videoShow_new: 'Nová video show',
  videoCategory_list: 'Seznam kategorií videí',
  videoCategory_detail: 'Detail kategorie videa',
  videoCategory_edit: 'Editace kategorie videa',
  videoCategory_new: 'Nová kategorie videa',
  videoManagement: 'Správa videí',
  videoDashboard: 'Statistiky videí',
  videoKpiReports: 'Reporty nahrávek videí',
  printArticle_list: 'Seznam tiskových článků',
  printArticle_edit: 'Editace tiskového článku',
  printArticle_new: 'Nový tiskový článek',
  eshopSubscriptionProduct_list: 'Seznam produktů',
  eshopSubscriptionProduct_new: 'Nový produkt',
  eshopSubscriptionProduct_edit: 'Editace produktu',
  eshopSubscriptionOrder_list: 'Seznam objednávek',
  eshopSubscriptionOrder_detail: 'Detail objednávky',
  eshopSubscriptionPayment_list: 'Seznam plateb',
  eshopSubscriptionPayment_detail: 'Detail platby',
  eshopSubscription_list: 'Seznam předplatných',
  eshopSubscription_new: 'Nové předplatné',
  eshopSubscription_edit: 'Editace předplatného',
  eshopSubscriber_list: 'Seznam předplatitelů',
  eshopHistory_list: 'Historie',
  eshopFeature_list: 'Seznam funkcí eshopu',
  eshopFeature_edit: 'Editace funkce eshopu',
  eshopDashboard: 'Dashboard eshopu',
  eshopTopProductSetting_edit: 'Editace TOP produktů',
  videoDailyReport: 'Report redakčních videí',
  videoPublishedReport: 'Report publikovaných videí',
  notification_detail: 'Detail notifikace',
  notification_edit: 'Editace notifikace',
  notification_list: 'Seznam notifikací',
  notification_new: 'Nová notifikace',
  permissionGroup_list: 'Seznam skupin oprávnění',
  permissionGroup_new: 'Nová skupina oprávnění',
  permissionGroup_detail: 'Detail skupiny oprávnění',
  permissionGroup_edit: 'Editace skupiny oprávnění',
  recipe_detail: 'Detail receptu',
  recipe_edit: 'Editace receptu',
  recipe_list: 'Seznam receptů',
  recipe_new: 'Nový recept',
  recipe_category_edit: 'Editace kategorie receptu',
  recipe_category_list: 'Seznam kategorií receptů',
  recipe_category_new: 'Nová kategorie receptu',
  hp_topic_offer_list: 'Nabídka článků na homepage',
  hp_topic_offer_list_old: 'Nabídka článků na homepage (staré)',
  woodWing_detail: 'Detail článku WoodWing',
  woodWing_list: 'Seznam článků WoodWing',
  kpi_nmh: 'KPI NMH',
  kpi_nmh_old: 'Historické KPI NMH',
  kpi_nmh_settings: 'Nastavení KPI NMH',
  gallery_detail: 'Detail galerie',
  gallery_edit: 'Editace galerie',
  gallery_list: 'Seznam galerií',
  gallery_new: 'Nová galerie',
  userLogout: 'Odhlášení uživatele',
  right_now_detail: 'Detail právě teď',
  right_now_new: 'Nový právě teď',
  right_now_edit: 'Editace právě teď',
  right_now_list: 'Seznam právě teď',
  news_of_the_day_detail: 'Detail zpráv dne',
  news_of_the_day_new: 'Nová zpráva dne',
  news_of_the_day_edit: 'Editace zpráv dne',
  news_of_the_day_list: 'Seznam zpráv dne',
  embedded_application_new: 'Nová VLM aplikace',
  embedded_application_edit: 'Editace VLM aplikace',
  embedded_application_list: 'Seznam VLM aplikací',
  menueditor_edit: 'Editace menu',
  menueditor_list: 'Seznam menu',
  menueditor_new: 'Vytvořit nové menu'
}

export default messages
